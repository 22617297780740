const AdminIndexPage = () => import(/* webpackChunkName: "admin-index" */ '@/components/pages/index.vue');
const BaseWeightIndex = () => import(/* webpackChunkName: "admin-base-weight" */ '@/components/pages/base_weights/index.vue');
const CompaniesIndex = () => import(/* webpackChunkName: "admin-companies" */ '@/components/pages/companies/index.vue');
const CustomerDetail = () => import(/* webpackChunkName: "admin-customers" */ '@/components/pages/customers/customers/_id.vue');
const CustomerGroupIndex = () => import(/* webpackChunkName: "admin-customers" */ '@/components/pages/customers/customer_groups/index.vue');
const CustomerIndex = () => import(/* webpackChunkName: "admin-customers" */ '@/components/pages/customers/customers/index.vue');
const CustomerMain = () => import(/* webpackChunkName: "admin-customers" */ '@/components/pages/customers/index.vue');
const RevenueStatisticsIndex = () => import(/* webpackChunkName: "admin-discount" */ '@/components/pages/statistics/revenue_statistics/index.vue');
const DiscountStatisticsIndex = () => import(/* webpackChunkName: "admin-discount" */ '@/components/pages/statistics/discount_statistics/index.vue');
const OverallStatisticsIndex = () => import(/* webpackChunkName: "admin-discount" */ '@/components/pages/statistics/overall_statistics/index.vue');
const ProductStatisticsIndex = () => import(/* webpackChunkName: "admin-discount" */ '@/components/pages/statistics/product_statistics/index.vue');
const StatisticsMain = () => import(/* webpackChunkName: "admin-discount" */ '@/components/pages/statistics/index.vue');
const DiscountIndex = () => import(/* webpackChunkName: "admin-discount" */ '@/components/pages/discount/discounts/index.vue');
const DiscountMain = () => import(/* webpackChunkName: "admin-discount" */ '@/components/pages/discount/index.vue');
const IconIndex = () => import(/* webpackChunkName: "admin-images" */ '@/components/pages/images/icons/index.vue');
const ImageIndex = () => import(/* webpackChunkName: "admin-images" */ '@/components/pages/images/images/index.vue');
const ImageMain = () => import(/* webpackChunkName: "admin-images" */ '@/components/pages/images/index.vue');
const LoginPage = () => import(/* webpackChunkName: "admin-index" */ '@/components/pages/login.vue');
const PasswordReset = () => import(/* webpackChunkName: "admin-index" */ '@/components/pages/password_reset/_token.vue');
const PasswordResetRequest = () => import(/* webpackChunkName: "admin-index" */ '@/components/pages/password_reset/index.vue');
const PrinterIndex = () => import(/* webpackChunkName: "admin-printers" */ '@/components/pages/stores/printers/index.vue');
const PrinterRuleIndex = () => import(/* webpackChunkName: "admin-printers" */ '@/components/pages/stores/printers/rules.vue');
const ProductDetail = () => import(/* webpackChunkName: "admin-products" */ '@/components/pages/products/products/_id.vue');
const ProductGroupIndex = () => import(/* webpackChunkName: "admin-products" */ '@/components/pages/products/product_groups/index.vue');
const ProductIndex = () => import(/* webpackChunkName: "admin-products" */ '@/components/pages/products/products/index.vue');
const ProductMain = () => import(/* webpackChunkName: "admin-products" */ '@/components/pages/products/index.vue');
const PromotionDetail = () => import(/* webpackChunkName: "admin-discounts" */ '@/components/pages/discount/promotions/_id.vue');
const PromotionIndex = () => import(/* webpackChunkName: "admin-discounts" */ '@/components/pages/discount/promotions/index.vue');
const RegisterDetail = () => import(/* webpackChunkName: "admin-registers" */ '@/components/pages/stores/registers/_id.vue');
const RegisterIndex = () => import(/* webpackChunkName: "admin-registers" */ '@/components/pages/stores/registers/index.vue');
const RegisterLayoutDetail = () => import(/* webpackChunkName: "admin-register-layouts" */ '@/components/pages/register_layouts/_id.vue');
const RegisterLayoutIndex = () => import(/* webpackChunkName: "admin-register-layouts" */ '@/components/pages/register_layouts/index.vue');
const SidebarMenuIndex = () => import(/* webpackChunkName: "admin-sidebar-menus" */ '@/components/pages/sidebar_menus/index.vue');
const SidebarMenuDetail = () => import(/* webpackChunkName: "admin-sidebar-menus" */ '@/components/pages/sidebar_menus/_id.vue');
const RouteWrap = () => import(/* webpackChunkName: "admin-index" */ '@/components/pages/routeWrap.vue');
const ScaleIndex = () => import(/* webpackChunkName: "admin-scales" */ '@/components/pages/stores/scales/index.vue');
const PaymentTerminalIndex = () => import(/* webpackChunkName: "admin-scales" */ '@/components/pages/stores/paymentTerminals/index.vue');
const CustomerScannerIndex = () => import(/* webpackChunkName: "admin-scales" */ '@/components/pages/stores/customerScanners/index.vue');
const ShiftDetail = () => import(/* webpackChunkName: "admin-shift" */ '@/components/pages/stores/shifts/_id.vue');
const ShiftIndex = () => import(/* webpackChunkName: "admin-shifts" */ '@/components/pages/stores/shifts/index.vue');
const OrderDetail = () => import(/* webpackChunkName: "admin-order" */ '@/components/pages/stores/shifts/orders/_id.vue');
const StoreDetail = () => import(/* webpackChunkName: "admin-stores" */ '@/components/pages/stores/_id.vue');
const StoreIndex = () => import(/* webpackChunkName: "admin-stores" */ '@/components/pages/stores/index.vue');
const StoreLayoutDetail = () => import(/* webpackChunkName: "admin-stores" */ '@/components/pages/stores/storeLayouts/_id.vue');
const StoreLayoutArrangement = () => import(/* webpackChunkName: "admin-stores" */ '@/components/pages/stores/storeLayouts/arrangement.vue');
const StoreLayoutIndex = () => import(/* webpackChunkName: "admin-stores" */ '@/components/pages/stores/storeLayouts/index.vue');
const SubProductGroupDetail = () => import(/* webpackChunkName: "admin-products" */ '@/components/pages/products/sub_product_groups/_id.vue');
const SubProductGroupIndex = () => import(/* webpackChunkName: "admin-products" */ '@/components/pages/products/sub_product_groups/index.vue');
const RegisterThemeIndex = () => import(/* webpackChunkName: "admin-users" */ '@/components/pages/themes/index.vue');
const RegisterThemeDetail = () => import(/* webpackChunkName: "admin-shift" */ '@/components/pages/themes/_id.vue');
const UserIndex = () => import(/* webpackChunkName: "admin-users" */ '@/components/pages/users/index.vue');
const VatRateIndex = () => import(/* webpackChunkName: "admin-vat-rates" */ '@/components/pages/vat_rates/index.vue');
const RegisterRequestLogDetail = () => import(/* webpackChunkName: "admin-register-request-logs" */ '@/components/pages/register_request_logs/_id.vue');

const storeRoutes = [
  {
    path: '',
    name: 'stores',
    component: StoreIndex,
  },
  {
    path: '/stores/:storeId',
    name: 'stores-id',
    component: StoreDetail,
    redirect: { name: 'store-registers' },
    children: [
      {
        path: 'registers',
        component: RouteWrap,
        children: [
          { path: '', name: 'store-registers', component: RegisterIndex },
          { path: ':registerId', name: 'store-registers-id', component: RegisterDetail },
        ],
      },
      { path: 'printers', name: 'store-printers', component: PrinterIndex },
      { path: 'printer-rules', name: 'store-printer-rules', component: PrinterRuleIndex },
      { path: 'scales', name: 'store-scales', component: ScaleIndex },
      { path: 'payment-terminals', name: 'store-payment-terminals', component: PaymentTerminalIndex },
      { path: 'customer-scanners', name: 'store-customer-scanners', component: CustomerScannerIndex },
      {
        path: 'layouts',
        component: RouteWrap,
        children: [
          { path: '', name: 'store-store-layouts', component: StoreLayoutIndex },
          { path: ':storeLayoutId', name: 'store-store-layouts-id', component: StoreLayoutDetail },
          { path: ':storeLayoutId/arrangement', name: 'store-store-layouts-arrangement-id', component: StoreLayoutArrangement },
        ],
      },
      {
        path: 'shifts',
        component: RouteWrap,
        children: [
          { path: '', name: 'store-shifts', component: ShiftIndex },
          {
            path: ':shiftId',
            name: 'store-shifts-shift-id',
            component: ShiftDetail,
          },
          {
            path: ':shiftId/orders/:orderId',
            name: 'store-shifts-orders-order-id',
            component: OrderDetail,
          },
        ],
      },
    ],
  },
];

const customerRoutes = [
  {
    path: '',
    component: RouteWrap,
    name: 'customers-wrap',
    redirect: { name: 'customers' },
    children: [
      { path: '', name: 'customers', component: CustomerIndex },
      { path: ':id', name: 'customers-id', component: CustomerDetail },
    ],
  },
  { path: 'groups', name: 'customer-groups', component: CustomerGroupIndex },
];

const productRoutes = [
  {
    path: '',
    component: RouteWrap,
    name: 'products-wrap',
    redirect: { name: 'products' },
    children: [
      { path: '/products', name: 'products', component: ProductIndex },
      { path: '/products/:id', name: 'products-id', component: ProductDetail },
    ],
  },
  { path: '/product_groups', name: 'product-groups', component: ProductGroupIndex },
  {
    path: '/sub_product_groups',
    name: 'sub-product-groups-wrap',
    component: RouteWrap,
    children: [
      { path: '', name: 'sub-product-groups', component: SubProductGroupIndex },
      { path: ':id', name: 'sub-product-groups-id', component: SubProductGroupDetail },
    ],
  },
];

const statisticRoutes = [
  {
    path: '',
    component: RouteWrap,
    name: 'statistics-wrap',
    redirect: { name: 'overall-statistics' },
    children: [
      { path: 'overall-statistics', name: 'overall-statistics', component: OverallStatisticsIndex },
      { path: 'product-statistics', name: 'product-statistics', component: ProductStatisticsIndex },
      { path: 'revenue-statistics', name: 'revenue-statistics', component: RevenueStatisticsIndex },
      { path: 'discount-statistics', name: 'discount-statistics', component: DiscountStatisticsIndex },
    ],
  },
];

const discountRoutes = [
  {
    path: '',
    component: RouteWrap,
    name: 'discounts-wrap',
    redirect: { name: 'discounts' },
    children: [
      { path: 'discounts', name: 'discounts', component: DiscountIndex },
      {
        path: 'promotions',
        component: RouteWrap,
        children: [
          { path: '', name: 'promotions', component: PromotionIndex },
          { path: ':id', name: 'promotions-id', component: PromotionDetail },
        ],
      },
    ],
  },
];

const themeRoutes = [
  { path: '', component: RegisterThemeIndex, name: 'register-themes' },
  { path: ':id', component: RegisterThemeDetail, name: 'register-themes-id' },
];

const imageRoutes = [
  {
    path: '',
    component: RouteWrap,
    name: 'images-wrap',
    redirect: { name: 'images' },
    children: [
      { path: '', name: 'images', component: ImageIndex },
      // { path: ':id', name: 'images-id', component: CustomerDetail },
    ],
  },
  // { path: '/images', name: 'images', component: ImageIndex },
  { path: '/icons', name: 'icons', component: IconIndex },
];

export default [
  { path: '/', component: AdminIndexPage },
  { path: '/companies', component: CompaniesIndex },
  { path: '/users', component: UserIndex },
  { path: '/stores', component: RouteWrap, children: storeRoutes },
  { path: '/register_layouts', component: RegisterLayoutIndex },
  { path: '/register_layouts/:id', component: RegisterLayoutDetail, name: 'register-layouts-id' },
  { path: '/sidebar_menus', component: SidebarMenuIndex },
  { path: '/sidebar_menus/:id', component: SidebarMenuDetail, name: 'sidebar-menus-id' },
  { path: '/customers', component: CustomerMain, children: customerRoutes },
  {
    path: '/products', name: 'products-main', component: ProductMain, children: productRoutes,
  },
  {
    path: '/statistics', component: StatisticsMain, redirect: { name: 'Revenuestatistics' }, children: statisticRoutes,
  },
  {
    path: '/discounts_promotions', component: DiscountMain, redirect: { name: 'discounts' }, children: discountRoutes,
  },
  { path: '/vat_rates', component: VatRateIndex },
  { path: '/base_weights', component: BaseWeightIndex },
  { path: '/register_themes', component: RouteWrap, children: themeRoutes },
  {
    path: '/images', component: ImageMain, children: imageRoutes,
  },
  { path: '/login', component: LoginPage },
  { path: '/password_reset/:token', component: PasswordReset },
  { path: '/password_reset', component: PasswordResetRequest },
  { path: '/register_request_logs/:id', component: RegisterRequestLogDetail, name: 'register-request-logs-id' },
];
