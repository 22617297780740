import { createRouter, createWebHistory } from 'vue-router';
import axios from '@/services/axios';
import routes from '@/router/routes';
import store from '../store';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  const loggedIn = () => store.state.auth.user !== null && store.state.auth.loggedIn;

  // If user is logged in, continue
  if (loggedIn()) {
    return true;
  }

  // Else check if a token is in storage and try to login
  await store.dispatch('auth/FETCH_USER');
  if (loggedIn()) {
    return true;
  }

  // If user is navigating to login route, continue
  if (to.path === '/login' && !loggedIn()) {
    return true;
  }

  if (to.path.includes('/password_reset') && !loggedIn()) {
    return true;
  }

  // redirect to login else
  return '/login';
});

export default router;
