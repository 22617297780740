<template>
  <div class="hamburger">
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.hamburger {
  box-sizing: content-box;
  width: 35px;
  padding-left: 1rem;
  padding-right: 2rem;
  margin-left: -1rem;
  margin-right: -1rem;
  cursor: pointer;
}

.bar {
  height: 4px;
  background-color: var(--color-main);
  transition: all 0.5s;

  &:nth-child(1) {
    width: 100%;
    margin-bottom: 5px;
    .active & {
      transform: translateY(calc(9px)) rotate(45deg);
    }
  }

  &:nth-child(2) {
    width: 100%;
    transform: translateX(0);
    margin-bottom: 5px;
    .active & {
      opacity: 0;
      width: 0;
    }
  }

  &:nth-child(3) {
    width: 100%;
    .active & {
      transform: translateY(calc(-9px)) rotate(-45deg);
    }
  }

}
</style>
