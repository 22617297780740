/* eslint-disable no-shadow */
import axios from '@/services/axios';

const state = () => ({
  entity: {},
});

const getters = {};

const mutations = {
  setEntity(state, entity) {
    state.entity = entity;
  },
  setModalOpenState(state, payload) {
    if (!Object.hasOwnProperty.call(state, payload.modal)) {
      state[payload.modal] = { open: false };
    }
    state[payload.modal].open = payload.open;
  },
};

const actions = {
  openModal({ commit }, payload) {
    commit('setEntity', payload.entity);
    commit('setModalOpenState', {
      modal: payload.modal,
      open: true,
    });
  },
  closeModal({ commit }, modal) {
    commit('setModalOpenState', {
      modal,
      open: false,
    });
    commit('setEntity', {});
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
