/* eslint-disable no-shadow */
import axios from '@/services/axios';

const state = () => ({
  vatRates: [],
});

const getters = {};

const mutations = {
  SET_VAT_RATES(state, payload) {
    state.vatRates = payload.map((vatRate) => ({
      key: vatRate.id,
      value: vatRate['@id'],
      label: `${vatRate.name} (${vatRate.value * 100}%)`,
    }));
  },
};

const actions = {
  async LOAD_VAT_RATES({ commit }) {
    try {
      const { data } = await axios.get('/vat_rates');
      if (data['hydra:member']) {
        commit('SET_VAT_RATES', data['hydra:member']);
      }
    } catch (err) {
      console.error(err, err.message, err.response);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
