<template>
  <header class="pl-7 pr-4 logo">
    <div class="flex items-center">
      <Hamburger
        class="lg:hidden mr-4"
        :class="{ active: mobile }"
        @click="toggle()"
      />
      <router-link to="/" aria-label="Home">
        EVENPAY
      </router-link>
    </div>
    <div v-if="store.state.auth.loggedIn" class="flex items-center">
      <span class="text-primary text-sm mr-4">
        {{ store.state.auth.user.email }}
      </span>
      <router-link
        to="/"
        class="inline-block w-8 h-8"
        @click="logout"
      >
        <logout-icon />
      </router-link>
    </div>
  </header>

  <div v-if="store.state.auth.loggedIn" id="nav" :class="{ open: mobile }">
    <div class="relative h-full pt-16">
      <nav class="inner flex flex-col h-full">
        <transition-group name="default">
          <router-link
            v-for="link of nav"
            :key="link.id"
            :to="link.route"
            :class="{ 'router-link-active': link.active }"
            class="px-4 link"
            @click="toggle"
            v-text="link.label"
          />
        </transition-group>
      </nav>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import LogoutIcon from '@/assets/images/logout.svg';
import Scrolllock from '@/hooks/scrolllock';
import Hamburger from '../utils/Hamburger.vue';
import usePermissions from '@/hooks/permissions';

export default {
  components: {
    Hamburger,
    LogoutIcon,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const {
      isAdmin, isSuperAdmin, isStatistics, isProductManager,
    } = usePermissions();
    const { lock, unlock } = Scrolllock();

    const mobile = ref(false);
    const navItems = ref([
      {
        id: 'nav-company', route: { path: '/companies' }, icon: null, label: t('company.label', 2), visible: isSuperAdmin,
      },
      {
        id: 'nav-user', route: { path: '/users' }, icon: null, label: t('user.label', 2), visible: isAdmin,
      },
      {
        id: 'nav-store', route: { name: 'stores' }, icon: null, label: t('store.label', 2), visible: isAdmin,
      },
      {
        id: 'nav-register-layouts', route: { path: '/register_layouts' }, icon: null, label: t('registerLayout.label', 2), visible: isProductManager,
      },
      {
        id: 'nav-sidebar-menus', route: { path: '/sidebar_menus' }, icon: null, label: t('sidebarMenu.label', 2), visible: isProductManager,
      },
      {
        id: 'nav-products', route: { name: 'products-wrap' }, icon: null, label: t('product.label', 2), visible: isProductManager,
      },
      {
        id: 'nav-statistics', route: { name: 'statistics-wrap' }, icon: null, label: t('pages.statistics.title'), visible: isStatistics,
      },
      {
        id: 'nav-discounts', route: { name: 'discounts-wrap' }, icon: null, label: t('pages.discounts.title'), visible: isAdmin,
      },
      {
        id: 'nav-customers', route: { name: 'customers-wrap' }, icon: null, label: t('customer.label', 2), visible: isAdmin,
      },
      {
        id: 'nav-register-themes', route: { path: '/register_themes' }, icon: null, label: t('registerTheme.label', 2), visible: isAdmin,
      },
      {
        id: 'nav-base_weights', route: { path: '/base_weights' }, icon: null, label: t('baseWeight.label', 2), visible: isProductManager,
      },
      {
        id: 'nav-vat_rates', route: { path: '/vat_rates' }, icon: null, label: t('vatRate.label', 2), visible: isAdmin,
      },
      {
        id: 'nav-images', route: { name: 'images-wrap' }, icon: null, label: t('image.label', 2), visible: isProductManager,
      },
    ]);
    const nav = computed(() => navItems.value.filter((i) => i.visible));

    const toggle = (close) => {
      if (close === undefined) close = mobile.value;
      mobile.value = !close;
    };

    const logout = async () => {
      await store.dispatch('auth/LOGOUT');
      notify(t('login.logoutSuccess'));
      await router.push('/login');
    };

    return {
      store,
      currentRoute: router.currentRoute,
      lock,
      unlock,
      mobile,
      nav,
      toggle,
      logout,
    };
  },
};
</script>

<style lang="scss" scoped>
header {
  @apply bg-white;

  height: 50px;
  box-shadow: 0 0 1px 0 gray;
  z-index: 110;
}

.logo {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

 a {
   @apply text-primary font-bold text-xl;
  }
}

#nav {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  background-color: white;
  box-shadow: 0 0 1px 0 gray;
  padding-left: 1rem;
  transition: transform 300ms ease-in-out;
  transform: translateX(-100%);

  @screen sm {
    width: 50vw;
    max-width: 300px;
  }

  @screen lg {
    transform: translateX(0);
  }

  @screen xl {
    width: 15vw;
  }

  &.open {
    transform: translateX(0);
  }
}

.inner,
.hamburger {
  pointer-events: all;
}

.link {
  @apply border-t border-l border-b border-transparent text-base px-3 py-2;

  font-weight: 500;

  &:hover,
  &.router-link-active {
    @apply border-t border-l border-b border-primary text-primary;
  }

  &:hover {
    @apply bg-violet-light text-secondary;
  }
}
</style>
