/* eslint-disable no-shadow */
import axios from '@/services/axios';

const decodeToken = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64).split('')
      .map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''),
  );

  return JSON.parse(jsonPayload);
};

const state = () => ({
  user: null,
  loggedIn: false,
});

const getters = {};

const mutations = {
  SET_USER(state, data) {
    state.user = data;
    state.loggedIn = state.user != null;
  },
};

const actions = {
  async FETCH_USER({ commit }) {
    const auth = JSON.parse(localStorage.getItem('auth'));
    if (auth && auth.token && auth.user) {
      try {
        const { data: user } = await axios.get(`/users/${auth.user}`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        commit('SET_USER', user);
      } catch (err) {
        console.error(err, err.message, err.response);
      }
    }
  },
  async LOGIN({ commit, dispatch }, data) {
    try {
      const { data: response } = await axios.post('/login', {
        username: data.email,
        password: data.password,
      });

      if (response.token) {
        localStorage.setItem('auth', JSON.stringify(response));
      }

      dispatch('FETCH_USER');
    } catch (err) {
      console.error(err, err.message, err.response);
      throw err;
    }
  },
  LOGOUT({ commit }) {
    localStorage.removeItem('auth');
    commit('SET_USER', null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
