<template>
  <router-link
    v-bind="$props"
    :to="route"
  >
    <font-awesome-icon v-if="isDeleted" icon="trash-can" class="icon mr-2 text-gray-400" />
    <span>
      <slot />
    </span>
  </router-link>
</template>

<script>
import { RouterLink } from 'vue-router';
import { omit } from 'lodash/object';
import getIdFromIri from '@/utils/getIdFromIri';

export default {
  name: 'AdminEntityLink',
  props: {
    ...(omit(RouterLink.props, 'to')),
    to: {
      type: null,
      required: true,
    },
    deleted: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    entityIri() {
      if (typeof this.to === 'object') {
        return this.to['@id'];
      }
      return this.to;
    },
    isDeleted() {
      return this.deleted || (typeof this.to === 'object' && this.to.deleted);
    },
    entityClass() {
      return this.entityIri?.split('/')[1].replaceAll('_', '-');
    },
    entityId() {
      return getIdFromIri(this.entityIri);
    },
    route() {
      switch (this.entityClass) {
        case 'product-groups':
          return {
            name: `${this.entityClass}`,
            query: {
              productGroup: this.entityIri,
            },
          };
        case 'customer-groups':
          return {
            name: 'customer-groups',
            query: {
              id: this.entityId,
            },
          };
        case 'discounts':
          return {
            name: 'discounts',
            query: {
              id: this.entityId,
            },
          };
        default:
          return {
            name: `${this.entityClass}-id`,
            params: {
              id: this.entityId,
            },
          };
      }
    },
  },
};
</script>
