import { useStore } from 'vuex';
import { computed } from 'vue';

export default function usePermissions() {
  const store = useStore();
  const isSuperAdmin = computed(() => (
    store.state.auth.loggedIn
    && store.state.auth.user?.roles?.includes('ROLE_SUPER_ADMIN')
  ));
  const isAdmin = computed(() => (
    store.state.auth.loggedIn
    && (
      store.state.auth.user?.roles?.includes('ROLE_ADMIN')
      || store.state.auth.user?.roles?.includes('ROLE_SUPER_ADMIN')
    )
  ));
  const isStatistics = computed(() => (
    isAdmin.value
    || (
      store.state.auth.loggedIn
      && store.state.auth.user?.roles?.includes('ROLE_STATISTICS')
    )
  ));
  const isProductManager = computed(() => (
    isAdmin.value
    || (
      store.state.auth.loggedIn
      && store.state.auth.user?.roles?.includes('ROLE_PRODUCT_MANAGER')
    )
  ));

  return {
    isSuperAdmin,
    isAdmin,
    isStatistics,
    isProductManager,
  };
}
