import Qs from 'qs';
import axios from '@/services/axios';

const setup = (store, router) => {
  axios.interceptors.request.use(
    (config) => {
      const authData = JSON.parse(localStorage.getItem('auth'));
      if (authData) {
        config.headers.Authorization = `Bearer ${authData.token}`;
      }

      config.paramsSerializer = (params) => Qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false,
      });

      return config;
    },
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    (res) => res,
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== '/login' && err.response) {
        if (err.response.status === 401) {
          await store.dispatch('auth/LOGOUT');
          await router.push('/login');
        }
      }

      return Promise.reject(err);
    },
  );
};

export default setup;
