import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import { createI18n } from 'vue-i18n';
import VueScrollTo from 'vue-scrollto';
import smoothscroll from 'smoothscroll-polyfill';
import Notifications from '@kyvg/vue3-notification';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faBadge, faPercent, faQrcode, faPenToSquare, faTrashCan, faList, faEye, faArrowUp, faArrowDown,
} from '@fortawesome/pro-solid-svg-icons';
import store from './store';
import router from './router';
import checkView from './vue-check-view';

import de from './translations/de';

import App from '@/App.vue';
import Go from '@/components/utils/Go.vue';
import AdminEntityLink from '@/components/utils/AdminEntityLink.vue';
import Image from '@/components/utils/Image.vue';

import './assets/styles/main.scss';
import setupInterceptors from '@/services/setupInterceptors';

// polyfills
// --------------------------------
smoothscroll.polyfill();

// I18n
// --------------------------------
const i18n = createI18n({
  legacy: false, // needed for Composition API
  globalInjection: true,
  locale: 'de',
  fallbackLocale: 'de',
  messages: { de },
});

// app
// --------------------------------
const app = createApp(App);

const head = createHead();
app.use(head);

app.use(store);
app.use(router);
app.use(i18n);
app.use(checkView, { insideThreshold: () => (window.innerWidth < 960 ? 0.1 : 0.15) });
app.use(VueScrollTo);
app.use(Notifications);

// api
// --------------------------------
setupInterceptors(store, router);

// misc
// --------------------------------
window.getPath = () => window.location.pathname.replace(/\/$/, '');
window[atob('amt3ZWI=')] = atob('Tm8sIHRoaXMgaXMgTmljayE=');
app.config.globalProperties.$tryCDN = (url, width) => url;

//  if (url && url.includes('backend.jkweb.dev')) {
//  const el = document.createElement('a');
//  el.href = url;
//  return `https://jkweb-dev.b-cdn.net${el.pathname}${width ? `?width=${width}` : ''}`;
// }

// i18n
// --------------------------------

// custom components
// --------------------------------
app.component('Go', Go);
app.component('Image', Image);
app.component('AdminEntityLink', AdminEntityLink);

// Fontawesome
// --------------------------------
library.add(faBadge, faPercent, faQrcode, faPenToSquare, faTrashCan, faList, faEye, faArrowUp, faArrowDown);
app.component('FontAwesomeIcon', FontAwesomeIcon);

app.mount('#app');
if (!window.prerender) setTimeout(() => window.document.body.classList.remove('no-anim'), 100);
