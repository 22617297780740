import { createStore, createLogger } from 'vuex';
import auth from './modules/auth';
import vatRates from './modules/vatRates';
import modals from './modules/modals';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    auth,
    vatRates,
    modals,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
