<template>
  <div id="app-root">
    <div id="popup" />
    <Navigation />

    <main class="px-4 pt-4" :class="{ 'padding-for-nav': loggedIn }">
      <router-view />
    </main>

    <notifications position="bottom right" />
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Navigation from '@/components/structure/Navigation.vue';

export default {
  components: {
    Navigation,
  },
  setup() {
    const store = useStore();
    const loggedIn = computed(() => store.state.auth.loggedIn);
    const colorMain = computed(() => (store.state.auth.loggedIn ? store.state.auth.user.company.adminColorMain : '#ae2e10'));
    const colorLight = computed(() => (store.state.auth.loggedIn ? store.state.auth.user.company.adminColorLight : 'white'));
    const colorDark = computed(() => (store.state.auth.loggedIn ? store.state.auth.user.company.adminColorDark : 'gray'));
    const colorHighlight = computed(() => (store.state.auth.loggedIn ? store.state.auth.user.company.adminColorHighlight : 'red'));

    return {
      loggedIn,
      colorMain,
      colorDark,
      colorLight,
      colorHighlight,
    };
  },
};
</script>

<style lang="scss" scoped>
.padding-for-nav {
  @screen lg {
    padding-left: calc(min(25vw, 300px) + 1rem);
  }

  @screen xl {
    padding-left: calc(min(300px, 15vw) + 1rem);
  }
}

 #app-root {
   --color-main: v-bind(colorMain);
   --color-light: v-bind(colorLight);
   --color-dark: v-bind(colorDark);
   --color-highlight: v-bind(colorHighlight);

   --color-primary: v-bind(colorMain);
   --color-secondary: v-bind(colorDark);
   --color-active: v-bind(colorMain);
 }
</style>
